import Head from "next/head";

export default function NotFound() {
	return (
		<>
			<Head>
				<title>Not Found | Algorythmic</title>
				<meta property="og:title" content="Not Found | Algorythmic" />
				<meta property="og:url" content="https://algorythmic.com/" />
				<meta name="version" content="v2.0.1" />
			</Head>
			<div className="content-container">
				<div className="intro">
					<img src="/Algorythmic.svg" alt="Algorythmic logo" id="flag" />
					<p></p>
				</div>
			</div>
			<div className="content-container">
				<div className="intro-two">
					<h2>Not found...</h2>
					<p></p>
					<p id="footer">
						{/* <span className="emoji">⛰️ ⛰️ ⛰️</span>&emsp; */}
						<span className="copyright">
							team@algorythmic.com | +01 720-706-4786 | &copy; Copyright 2023, Algorythmic LLC
						</span>
					</p>
				</div>
			</div>
			{/* <Script src="/textblock.min.js" strategy="afterInteractive" /> */}
		</>
	);
}
